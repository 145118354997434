<template>
  <v-card
    class="d-flex flex-column"
    @click="$emit('click')"
    :class="order.status === 'new' ? orderCssClass : ''"
    ripple
    height="100%"
  >
    <v-card-text class="py-1 grow">
      <v-list three-line subheader style="background-color: initial">
        <v-list-item class="px-0">
          <v-list-item-avatar
            tile
            width="90"
            class="display-2 font-weight-light"
            >{{ order.table.number }}</v-list-item-avatar
          >

          <v-list-item-content>
            <v-list-item-title class="headline">
              <v-icon class="mr-3" size="28">{{
                order.type == 'order'
                  ? 'mdi-shopping'
                  : order.type == 'waiter'
                  ? 'icon-waiter'
                  : 'icon-bill'
              }}</v-icon>
              {{ order.status == 'new' ? 'Nuevo' : 'Aceptado' }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ order.details }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="pa-4">
      <v-icon class="mr-2 grey--text text--darken-3">mdi-history</v-icon>
      {{ ago }}
      <v-spacer></v-spacer>

      <span class="text-xs-right" v-if="order.type == 'order'">
        <v-icon class="black--text mr-2">mdi-currency-eur</v-icon>
        {{ order.price | currency_money }}
      </span>

      <span class="text-xs-right" v-if="order.type == 'bill'">
        <span v-if="order.object_data.payment_type == 'card'">
          <v-icon class="black--text mr-2">mdi-credit-card-outline</v-icon>
          Pago con tarjeta
        </span>

        <span v-else-if="order.object_data.payment_type == 'cash'">
          <v-icon class="black--text mr-2">mdi-currency-eur</v-icon>
          Pago en efectivo
        </span>

        <span v-else>
          <v-icon class="black--text mr-2">mdi-credit-card-outline</v-icon>
          Ticket restaurant
        </span>
      </span>
    </v-card-actions>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
  props: {
    order: Object,
  },

  data: () => ({
    ago: '',

    orderCssClass: '',
  }),

  computed: {},

  mounted() {
    dayjs.extend(relativeTime);

    dayjs.locale(this.$i18n.locale);
    this.ago = dayjs(this.order.updated_at).from(dayjs());

    // Check for the class.
    this.changeCssClass();

    // Set the new "ago" every minute
    this.interval = setInterval(() => {
      this.ago = dayjs(this.order.updated_at).from(dayjs());

      // Check for the class.
      this.changeCssClass();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    changeCssClass() {
      const seconds = dayjs().diff(dayjs(this.order.updated_at), 'seconds');

      if (seconds < 300) {
        // 5 minutes
        this.orderCssClass = 'order-green';
      } else if (seconds < 600) {
        // 10 minutes
        this.orderCssClass = 'order-orange';
      } else {
        // More than 10 minutes since the order was placed
        this.orderCssClass = 'order-red';
      }
      //
      // console.log(
      //   dayjs().diff(dayjs(this.order.created_at), 'seconds'),
      //   this.orderCssClass
      // );
    },
  },
};
</script>

<style>
/* Orders animation */
.order-green {
  animation: pulse-green 3s;
  -moz-animation: pulse-green 3s infinite; /* Firefox */
  -webkit-animation: pulse-green 3s infinite; /* Safari and Chrome */
}

.order-orange {
  animation: pulse-orange 3s;
  -moz-animation: pulse-orange 3s infinite; /* Firefox */
  -webkit-animation: pulse-orange 3s infinite; /* Safari and Chrome */
}

.order-red {
  animation: pulse-red 3s;
  -moz-animation: pulse-red 3s infinite; /* Firefox */
  -webkit-animation: pulse-red 3s infinite; /* Safari and Chrome */
}

@keyframes pulse-green {
  0% {
    background: white;
  }
  50% {
    background: #66bb6a;
  }
  100% {
    background: white;
  }
}

@-moz-keyframes pulse-green /* Firefox */ {
  0% {
    background: white;
  }
  50% {
    background: #66bb6a;
  }
  100% {
    background: white;
  }
}

@-webkit-keyframes pulse-green /* Firefox */ {
  0% {
    background: white;
  }
  50% {
    background: #66bb6a;
  }
  100% {
    background: white;
  }
}

@keyframes pulse-orange {
  0% {
    background: white;
  }
  50% {
    background: #ffca28;
  }
  100% {
    background: white;
  }
}

@-moz-keyframes pulse-orange /* Firefox */ {
  0% {
    background: white;
  }
  50% {
    background: #ffca28;
  }
  100% {
    background: white;
  }
}

@-webkit-keyframes pulse-orange /* Firefox */ {
  0% {
    background: white;
  }
  50% {
    background: #ffca28;
  }
  100% {
    background: white;
  }
}

@keyframes pulse-red {
  0% {
    background: white;
  }
  50% {
    background: #ff5252;
  }
  100% {
    background: white;
  }
}

@-moz-keyframes pulse-red /* Firefox */ {
  0% {
    background: white;
  }
  50% {
    background: #ff5252;
  }
  100% {
    background: white;
  }
}

@-webkit-keyframes pulse-red /* Firefox */ {
  0% {
    background: white;
  }
  50% {
    background: #ff5252;
  }
  100% {
    background: white;
  }
}
</style>
