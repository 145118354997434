<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center" align-items="stretch">
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">
            {{ $t('orders.title') }}
          </h2>
        </v-col>

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="orders.length == 0">
          <v-alert border="left" colored-border type="info" elevation="2">{{
            $t('orders.noOrders')
          }}</v-alert>
        </v-col>

        <!-- Orders cards -->
        <v-col cols="12">
          <v-row align-items="stretch">
            <v-col
              class="px-2 py-2"
              cols="12"
              sm="6"
              md="4"
              xl="3"
              v-for="order in orders"
              :key="order.id"
            >
              <eximyr-order :order="order" @click="open(order)"></eximyr-order>
            </v-col>
          </v-row>
        </v-col>

        <!-- Start pagination -->
        <v-col cols="12">
          <div class="text-right my-4">
            <v-pagination
              v-model="page"
              :length="last_page"
              :total-visible="7"
              @input="paginate"
            ></v-pagination>
          </div>
        </v-col>
        <!-- End pagination -->
      </v-row>
    </v-container>

    <!-- Details dialog -->
    <eximyr-order-dialog
      :order="order"
      :products="products"
      :daily-menus="dailyMenus"
      :hide-history-button="false"
      :hide-ticket-view-button="false"
      @close="close"
      @save="save($event)"
      @showTicketDialog="showTicketDialog"
    ></eximyr-order-dialog>
    <!-- Details dialog -->

    <!-- Ticket View Dialog -->
    <EasyServeTicketView
      :dialog="ticketDialog"
      :order="order"
      :products="products"
      :daily-menus="dailyMenus"
      @close="close"
      @showDialog="showDialog"
      @updatePaid="updatePaid($event)"
    ></EasyServeTicketView>
    <!-- Ticket View Dialog -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';

import EximyrLoading from '../components/EximyrLoading';

import EximyrOrder from './components/EximyrOrder';
import EximyrOrderDialog from './components/EximyrOrderDialog';
import EasyServeTicketView from './components/EasyServeTicketView';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EximyrOrder,
    EximyrOrderDialog,
    EasyServeTicketView,
  },

  data: () => ({
    page: null,

    loading: false,
    ticketDialog: false,
    order: null,
  }),

  computed: {
    ...mapGetters('orders', {
      orders: 'pending',
      last_page: 'last_page',
    }),

    ...mapGetters('orderDetails', {
      items: 'items',
      products: 'products',
      dailyMenus: 'dailyMenus',
    }),
  },

  created() {
    // Set the current page if there is one set
    this.page = this.currentPage();
    this.load();
  },

  watch: {
    $route() {
      this.load();
    },
  },

  methods: {
    currentPage() {
      if (isNaN(this.$route.query.page)) {
        return 1;
      } else {
        return parseInt(this.$route.query.page);
      }
    },

    load() {
      this.loading = true;

      // Create the payload
      const payload = {
        restaurant: this.$route.params.restaurant,
        page: this.page,
      };

      this.$store
        .dispatch('orders/loadPending', payload)
        .then(results => {
          // Reset all errors
          this.clearErrors();

          if (this.page > results.data.last_page) {
            // Reload
            this.paginate(results.data.last_page);
          }
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    paginate(page) {
      this.page = page ? page : this.currentPage();

      this.$router
        .push({
          name: 'orders',
          params: {
            restaurant: this.$route.params.restaurant,
          },
          query: {
            page: this.page,
          },
        })
        .catch(() => {});
    },

    open(order) {
      this.loading = true;

      // Load the selected order
      // First we load the order data (only if it is an order)
      if (order.type != 'order') {
        this.order = order;
        this.loading = false;
        return;
      }

      // Now we have an order here ... lets load the data first
      const payload = {
        restaurant: this.$route.params.restaurant,
        order: order.id,
      };

      this.$store
        .dispatch('orderDetails/load', payload)
        .then(response => {
          // Clear all errors
          this.clearErrors();
          this.order = response.data;
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      this.ticketDialog = false;
      this.order = null;
    },

    save(payload) {
      // Start loader
      this.loading = true;
      this.$store
        .dispatch('orders/updateStatus', payload)
        .then(() => {
          // Reload data
          this.load();
        })
        .catch(error => {
          this.storeError(error);
          this.loading = false;
        })
        .finally(() => {
          this.close();
        });
    },

    updatePaid(orders) {
      // Update all orders
      const payload = {
        restaurant: this.$route.params.restaurant,
        orders: orders,
      };

      this.$store
        .dispatch('orders/updatePaid', payload)
        .then(() => {
          // Close any dialog (we can get here from tiket view )
          this.close();

          // Reload all data
          this.load();
        })
        .catch(error => {
          // Some error here
          this.storeError(error);
        });
    },

    showTicketDialog() {
      this.dialog = false;
      this.ticketDialog = true;
    },

    showDialog() {
      this.dialog = true;
      this.ticketDialog = false;
    },
  },
};
</script>
